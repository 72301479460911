


































































































import WidgetMixins from '@/mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
const paymentModule = namespace('payments');

interface IPaymentResponse {
  amount: number;
  reference: string;
  status: string;
  reason: string;
}

@Component({
  name: 'VerifyPayment',
  components: {
    SnackBar: loadWidget('widgets/SnackBar'),
    InternetConnection: loadWidget('pages/InternetConnection'),
    ProgressLoader: loadWidget('widgets/CircularProgressLoader'),
  },
})
export default class VerifyPayment extends mixins(WidgetMixins) {
  @paymentModule.Getter('getPaymentResponse') verifyPayment!: IPaymentResponse;

  isPaymentSuccessful = false;
  amount = 0;

  @Watch('verifyPayment')
  onPaymentVerified(payload: IPaymentResponse): void {
    this.isPaymentSuccessful = payload?.status === 'success';
  }
  created(): void {
    if (this.$route.query.reference || this.$route.query.trxref) {
      this.$store.dispatch(
        'payments/verifyPayment',
        this.$route.query.reference ?? this.$route.query.trxref
      );
    }
  }
}
